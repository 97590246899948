import isNull from 'lodash/isNull'
import localStorage from './localStorage'
const SAHAGROUPFAIR = process.env.REACT_APP_SAHAGROUPFAIR || ''

const CustomerProfile = process.env.REACT_APP_PROFILE || 'SPC-FIT-PROFILE'
const ShoppingStorage = process.env.REACT_APP_SHPOPPING_STORAGE || 'SPC-SHOPPING-LIST'

const customerProfile = {
  isLogin: () => {
    return !isNull(localStorage.get(CustomerProfile))
  },
  logout: () => {
    // localStorage.clear();
    localStorage.clearExcept([SAHAGROUPFAIR,ShoppingStorage]);
    return true
  },
  setProfile: profile => {
    localStorage.set({ key: CustomerProfile, value: JSON.stringify(profile) })
  },
  getProfile: () => {
    const customerProfile = localStorage.get(CustomerProfile)

    if (isNull(customerProfile)) return {}

    return JSON.parse(customerProfile)
  }
}

export default customerProfile